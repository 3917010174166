export default class SetNewAdjustmentRequestTargetScenarioCommand {
  constructor (context) {
    this.context = context
  }
  async execute () {
    let me = this
    let checkBox = `<label><input class="val" type="checkbox" checked/>Создать новый сценарий</label></div>`
    let message
    let finalMessage
    let adjustmentRequestId = this.context.getModel().id
    let targetScenarioId = this.context.getModel().new_goal_scenario

    this.context.$http.get(
      `${this.context.$config.api}/registryservice/plugins/execute/CheckAdjustmentRequestVerificationQuery?adjustmentRequestId=${adjustmentRequestId}&targetScenarioId=${targetScenarioId}`
    ).then((response) => {
      this.context.$msgbox.close()

      if (response.data.is_new_costs_verified || response.data.is_carry_over_costs_verified) {
        message = `<div><p>Внимание! Установлена блокировка затрат.</p><br><br>`
        message += `<p>Сценарий №${response.data.old_target_scenario} будет отклонен без возможности внесения последующих изменений.</p><br><br>`
        message += `<p>Продолжит согласование новый сценарий, сформированный путем копирования:</p>`

        if (response.data.is_carry_over_costs_verified) {
          message += `<li>переходящих затрат из сценария №${response.data.old_target_scenario}</li><br>`
        } else {
          message += `<li>переходящих затрат из сценария №${response.data.target_scenario}</li><br>`
        }

        if (response.data.is_new_costs_verified) {
          message += `<li>новых затрат из сценария №${response.data.old_target_scenario}</li><br>`
        } else {
          message += `<li>новых затрат из сценария №${response.data.target_scenario}</li><br>`
        }

        message += `<p>Вы можете отказаться от создания нового сценария и перезаписать выбранный сценарий, для этого снимите галку "Создать новый сценарий".</p><br><br>`

        finalMessage = message
        finalMessage += checkBox
      } else {
        message = `<div><p>Внимание!</p><br><br>`
        message += `<p>Сценарий №${response.data.old_target_scenario} будет отклонен без возможности внесения последующих изменений.</p><br><br>`
        message += `<p>Продолжит согласование выбранный сценарий №${response.data.target_scenario}</p></div>`

        finalMessage = message
      }

      this.context.$msgbox({
        message: finalMessage,
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let isCreateNewScenario
            if (instance.$el.getElementsByClassName('val')[0] !== undefined) {
              isCreateNewScenario = instance.$el.getElementsByClassName('val')[0].checked
            } else {
              isCreateNewScenario = false
            }

            let adjustmentRequestData = {
              'adjustmentRequestId': adjustmentRequestId,
              'targetScenarioId': targetScenarioId,
              'async': 'true',
              'is_new_costs_verified': response.data.is_new_costs_verified,
              'is_carry_over_costs_verified': response.data.is_carry_over_costs_verified,
              'is_create_new_scenario': isCreateNewScenario
            }

            this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetNewAdjustmentRequestTargetScenarioCommand`,
              adjustmentRequestData,
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }).then((response) => {
              me.context.$msgbox({
                type: 'info',
                message: 'Задача по смене целевого сценария поставлена в очередь, по завершении Вы получите уведомление'
              })
              this.context.$loading().close()
              me.context.getParentContext().getCard().$emit('cancelChanges')
            })
            done()
          } else {
            this.context.$loading().close()
            this.context.$msgbox.close()
            done()
          }
        }
      })
    })
  }
}
